.message-list {
  overflow: auto;
  max-height: none;
  height: 500px;
}

.rce-mbox-right {
  background: #e8a3a32e;
  box-shadow: none;
}

.rce-mbox-right .rce-mbox-time {
  color: 767676;
}

.rce-mbox-right-notch {
  fill: #e8a3a32e;
}

.rce-mbox-text {
  font-size: 15px;
  font-family: Lato;
  margin-inline: 20px;
}

.rce-mbox-title {
  font-size: 18px;
  font-family: Lato;
  color: #0077c8;
  font-weight: 600;
  margin-top: 15px;
}

.rce-mbox-right .rce-mbox-text {
  color: black;
  margin-inline: 20px;
}

.rce-mbox-right .rce-mbox-text a {
  color: black;
  margin-inline: 20px;
}

.rce-mbox-right .rce-mbox-title {
  color: #0077c8;
  font-weight: 600;
  margin-top: 15px;
}

.rce-avatar-container .rce-avatar {
  width: 100%;
  height: unset;
}

.rce-mbox-right .rce-avatar-container.default {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border-width: 0.5px;
  border-color: #fff;
  border-style: solid;
}

.rce-avatar-container.default {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border-width: 0.5px;
  border-color: #000;
  border-style: solid;
  margin-left: 15px;
}

.rce-mbox {
  max-width: 800px;
}
