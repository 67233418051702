@font-face {
  font-family: "Lato";
  src: url("./assets/fonts/Lato-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Lato";
  src: url("./assets/fonts/Lato-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

/* @font-face {
  font-family: "Lato";
  src: url("./assets/fonts/SourceSans3-VariableFont_wght.ttf")
    format("truetype");
} */

body {
  margin: 0;
  font-family:
    "Lato",
    -apple-system,
    BlinkMacSystemFont;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f6f7fb;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  color: #c40f0d;
}

.message-list {
  max-height: 500px;
  overflow-y: auto;
}

.rce-mbox {
  background: #c40f0d;
}
